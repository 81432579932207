.preloader {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #15181B;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    overflow: hidden;
  }
  &__image {
    width: 146px;
    height: 48px;
    margin-bottom: 32px;
    object-fit: cover;
  }
  &__line{
    width: 160px;
    height: 4px;
    background: #323940;
    border-radius: 5px;
  }
}

.MuiLinearProgress-root{
  width: 160px;
  height: 4px;
  background: #323940;
  border-radius: 5px;
  span {
    background: #F1F3F6;
    border-radius: 5px;
  }
}