
body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  max-height: 100%;
  font-family: "Gilroy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html {
  scroll-behavior: smooth;
}
ul, h1, h4, li, h3{
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a{
  background-color: transparent;
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #15181B;
}

::-webkit-scrollbar-thumb {
  background-color: #929FB5;
  border-radius: 4px;
}



